.path-32-copy {
  align-self: stretch;
  height: 1px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.oval-copy-icon {
  height: 48px;
  width: 48px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
  z-index: 1;
}
.oval-copy-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-2xl) 0 var(--padding-lgi);
}
.vinod-palli {
  position: relative;
  letter-spacing: 0.23px;
  display: inline-block;
  min-width: 75px;
  z-index: 1;
}
.vinod-palli-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-4xs) 0 var(--padding-9xs);
}
.business-analyst {
  position: relative;
  font-size: var(--font-size-2xs);
  letter-spacing: 0.17px;
  font-weight: 500;
  color: var(--color-darkgray);
  text-align: center;
  display: inline-block;
  min-width: 88px;
  z-index: 2;
}
.frame-parent1 {
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent1,
.profile-content,
.profile-info {
  display: flex;
  align-items: flex-start;
}
.profile-info {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.profile-content {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 var(--padding-23xl) 0 var(--padding-25xl);
}
.logout-content-child {
  height: 18.5px;
  width: 17.7px;
  position: relative;
  min-height: 19px;
  z-index: 1;
}
.logout {
  position: relative;
  letter-spacing: 0.28px;
  font-weight: 500;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}
.logout-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs-6) 0 0;
}
.logout-content,
.logout-content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.logout-content {
  justify-content: flex-start;
  gap: var(--gap-mini-2);
}
.logout-content-wrapper {
  justify-content: flex-end;
  padding: 0 var(--padding-32xl);
  font-size: var(--font-size-xs);
  color: var(--color-darkslategray-100);
}
.path-32-copy-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-7xl-9);
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-darkslategray-300);
  font-family: var(--font-roboto);
}
