.rectangle-copy-61 {
  height: 40px;
  width: 201px;
  position: relative;
  border-radius: var(--br-5xs);
  display: none;
}
.search {
  width: 45px;
  border: 0;
  outline: 0;
  font-family: var(--font-roboto);
  font-size: var(--font-size-sm);
  background-color: transparent;
  height: 17px;
  position: relative;
  letter-spacing: 0.2px;
  color: var(--color-dimgray-300);
  text-align: left;
  display: inline-block;
  mix-blend-mode: normal;
  padding: 0;
  z-index: 1;
}
.combined-shape-icon {
  width: 16.3px;
  height: 16.3px;
  position: relative;
  z-index: 1;
}
.rectangle-copy-6-parent,
.search-icon-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0 0;
}
.rectangle-copy-6-parent {
  flex: 1;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-2xs) var(--padding-sm) var(--padding-xs-7)
    var(--padding-lg);
  gap: var(--gap-xl);
}
.group-11-1 {
  width: 21px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.filter-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-6xs);
}
.frame-parent2 {
  width: 272px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-11xl);
  top: 0;
  z-index: 99;
  position: sticky;
}
.rectangle-copy-icon7 {
  width: 333px;
  height: 330px;
  position: relative;
  border-radius: var(--br-7xs);
  display: none;
  max-width: 100%;
}
.cost-of-resources {
  text-decoration: none;
  position: relative;
  letter-spacing: 0.36px;
  font-weight: 700;
  color: inherit;
  z-index: 1;
}
.rectangle-copy-3 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-7xs);
  width: 100%;
  height: 100%;
  display: none;
}
.dropdown-arrow-icon {
  position: absolute;
  top: 7.3px;
  left: 5.3px;
  width: 11.3px;
  height: 11.3px;
  object-fit: contain;
  z-index: 2;
}
.header-dropdown {
  height: 26px;
  width: 26px;
  position: relative;
  box-shadow: 0 5px 5px rgba(23, 148, 239, 0.05),
    0 10px 30px rgba(23, 148, 239, 0.5);
  border-radius: var(--br-7xs);
  z-index: 1;
}
.header-row {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  font-size: var(--font-size-lg);
  color: var(--color-darkslategray-200);
}
.rectangle-copy-5 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  flex-shrink: 0;
  mix-blend-mode: normal;
  z-index: 1;
}
.label {
  position: relative;
  letter-spacing: 0.2px;
  font-weight: 500;
  display: inline-block;
  min-width: 31px;
  z-index: 2;
}
.sort-labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-2xs) 0 0;
}
.sort-icon {
  width: 6.1px;
  height: 8.7px;
  position: relative;
  z-index: 2;
}
.sort-icons {
  width: 11.8px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs-3) var(--padding-8xs) 0 0;
  box-sizing: border-box;
}
.cost {
  position: relative;
  letter-spacing: 0.2px;
  font-weight: 500;
  display: inline-block;
  min-width: 26px;
  z-index: 2;
}
.cost-labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-7xs) 0 0;
}
.sort-icon1 {
  width: 6.1px;
  height: 8.7px;
  position: relative;
  z-index: 2;
}
.second-sort-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs-3) 0 0;
}
.profit,
.sort-icon2 {
  position: relative;
  z-index: 2;
}
.profit {
  letter-spacing: 0.2px;
  font-weight: 500;
  display: inline-block;
  min-width: 32px;
}
.sort-icon2 {
  width: 6.1px;
  height: 8.7px;
}
.header-dividers,
.profit-sort-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs-3) 0 0;
}
.header-dividers {
  align-self: stretch;
  overflow-x: auto;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-xs) var(--padding-mini) var(--padding-sm)
    var(--padding-3xs);
  position: relative;
  gap: var(--gap-xl);
  font-size: var(--font-size-xs);
  color: var(--color-gray-100);
}
.l,
.resource-1 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 72px;
  z-index: 1;
}
.l {
  min-width: 48px;
}
.resource-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs) 0 0;
}
.l1 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}
.resource-row,
.table-rows {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.resource-row {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.table-rows {
  width: 264px;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
}
.rectangle-copy-4 {
  height: 1px;
  width: 283px;
  position: relative;
  z-index: 1;
}
.row-divider {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs);
}
.l2,
.resource-2 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 72px;
  z-index: 1;
}
.l2 {
  min-width: 48px;
}
.third-resource-icon-shapes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs) 0 0;
}
.l3 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}
.resource-2-parent,
.second-resource-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.resource-2-parent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.second-resource-row {
  width: 264px;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
}
.rectangle-copy-41 {
  height: 1px;
  width: 283px;
  position: relative;
  z-index: 1;
}
.second-row-divider {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs);
}
.l4,
.resource-3 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 72px;
  z-index: 1;
}
.l4 {
  min-width: 48px;
}
.fourth-resource-icon-shapes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs) 0 0;
}
.l5 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}
.resource-label-container,
.third-resource-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.resource-label-container {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.third-resource-row {
  width: 264px;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
}
.rectangle-copy-42 {
  height: 1px;
  width: 283px;
  position: relative;
  z-index: 1;
}
.third-row-divider {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs);
}
.l6,
.resource-4 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 72px;
  z-index: 1;
}
.l6 {
  min-width: 48px;
}
.sixth-resource-icon-shapes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs) 0 0;
}
.l7 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}
.fourth-resource-label-containe,
.fourth-resource-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.fourth-resource-label-containe {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.fourth-resource-row {
  width: 264px;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
}
.rectangle-copy-43 {
  height: 1px;
  width: 283px;
  position: relative;
  z-index: 1;
}
.fourth-row-divider {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs);
}
.l8,
.resource-5 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 72px;
  z-index: 1;
}
.l8 {
  min-width: 48px;
}
.eighth-resource-icon-shapes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs) 0 0;
}
.l9 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}
.fifth-resource-row,
.resource-5-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.resource-5-parent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.fifth-resource-row {
  width: 264px;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
}
.mask-icon,
.rectangle-copy-parent5 {
  border-radius: var(--br-7xs);
  max-width: 100%;
}
.rectangle-copy-parent5 {
  align-self: stretch;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  background-color: var(--color-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-xl) var(--padding-sm);
  box-sizing: border-box;
  gap: var(--gap-mini-6);
}
.mask-icon {
  width: 333px;
  height: 175px;
  position: relative;
  display: none;
  z-index: 0;
}
.oval-copy-2,
.oval-copy-3 {
  position: absolute;
  top: 0;
  left: 99.1px;
  border-radius: 50%;
  background-color: var(--color-gray-200);
  width: 103.1px;
  height: 103.1px;
  mix-blend-mode: normal;
  z-index: 2;
}
.oval-copy-2 {
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.frame-child2 {
  position: absolute;
  top: 32.5px;
  left: 97.6px;
  width: 60px;
  height: 65.7px;
  mix-blend-mode: overlay;
  z-index: 4;
}
.month {
  margin: 0;
  position: absolute;
  top: 21.5px;
  left: 0.4px;
  font-size: inherit;
  letter-spacing: 0.3px;
  line-height: 18px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  min-width: 66px;
  z-index: 6;
}
.oval-copy-2-parent {
  position: absolute;
  top: 60.5px;
  left: 0;
  width: 194.2px;
  height: 194.4px;
}
.oval-copy-3-parent {
  width: 202.2px;
  height: 254.9px;
  position: absolute;
  margin: 0 !important;
  right: -20.8px;
  bottom: -73.9px;
}
.separator1,
.upgrade-to-pro {
  position: relative;
  line-height: 18px;
  display: inline-block;
  z-index: 5;
}
.upgrade-to-pro {
  margin: 0;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  min-width: 122px;
}
.separator1 {
  font-size: var(--font-size-29xl);
  font-weight: 500;
  min-width: 128px;
  white-space: nowrap;
}
.rectangle-copy-icon8 {
  height: 38px;
  width: 130px;
  position: relative;
  border-radius: var(--br-5xs);
  display: none;
}
.upgrade-now {
  position: relative;
  font-size: var(--font-size-smi);
  letter-spacing: 0.32px;
  display: inline-block;
  font-family: var(--font-roboto);
  color: var(--color-cornflowerblue);
  text-align: center;
  min-width: 83px;
  z-index: 1;
}
.frame-wrapper2,
.group-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.group-button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-2xs) var(--padding-3xl) var(--padding-xs)
    var(--padding-6xl);
  background-color: var(--color-white);
  border-radius: var(--br-5xs);
  overflow: hidden;
  white-space: nowrap;
  z-index: 4;
}
.group-button:hover {
  background-color: var(--color-gainsboro);
}
.frame-wrapper2 {
  padding: 0 var(--padding-10xs);
}
.mask-parent,
.rectangle-copy-icon9 {
  position: relative;
  border-radius: var(--br-7xs);
  max-width: 100%;
}
.mask-parent {
  align-self: stretch;
  background: linear-gradient(22.11deg, #3275cb, #3caed6);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xl) var(--padding-5xl) var(--padding-6xl);
  box-sizing: border-box;
  gap: var(--gap-7xl-5);
  font-size: var(--font-size-lg);
  color: var(--color-white);
}
.rectangle-copy-icon9 {
  width: 333px;
  height: 330px;
  display: none;
}
.cost-of-goods {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.36px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  min-width: 120px;
  z-index: 1;
}
.rectangle-copy-31 {
  height: 26px;
  width: 26px;
  position: relative;
  border-radius: var(--br-7xs);
  display: none;
}
.dropdown-arrow-icon1 {
  height: 12px;
  width: 12px;
  position: relative;
  object-fit: contain;
  z-index: 2;
}
.cost-of-goods-parent,
.rectangle-copy-3-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.rectangle-copy-3-parent {
  width: 26px;
  border-radius: var(--br-7xs);
  background-color: var(--color-white);
  border: 0 solid var(--color-lightgray-100);
  box-sizing: border-box;
  justify-content: flex-start;
  padding: var(--padding-6xs) var(--padding-8xs);
  z-index: 1;
}
.cost-of-goods-parent {
  align-self: stretch;
  justify-content: space-between;
  gap: var(--gap-xl);
  font-size: var(--font-size-lg);
  color: var(--color-darkslategray-200);
}
.rectangle-copy-51 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  mix-blend-mode: normal;
  z-index: 1;
}
.label1 {
  position: relative;
  letter-spacing: 0.2px;
  font-weight: 500;
  display: inline-block;
  min-width: 31px;
  z-index: 2;
}
.label-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-2xs) 0 0;
}
.sort-icon3 {
  width: 6.1px;
  height: 8.7px;
  position: relative;
  z-index: 2;
}
.sort-wrapper {
  width: 11.8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs-3) var(--padding-8xs) 0 0;
  box-sizing: border-box;
}
.cost1 {
  position: relative;
  letter-spacing: 0.2px;
  font-weight: 500;
  display: inline-block;
  min-width: 26px;
  z-index: 2;
}
.cost-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-7xs) 0 0;
}
.sort-icon4 {
  width: 6.1px;
  height: 8.7px;
  position: relative;
  z-index: 2;
}
.sort-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs-3) 0 0;
}
.profit1,
.sort-icon5 {
  position: relative;
  z-index: 2;
}
.profit1 {
  letter-spacing: 0.2px;
  font-weight: 500;
  display: inline-block;
  min-width: 32px;
}
.sort-icon5 {
  width: 6.1px;
  height: 8.7px;
}
.rectangle-copy-5-parent,
.sort-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs-3) 0 0;
}
.rectangle-copy-5-parent {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-xs) var(--padding-mini) var(--padding-sm)
    var(--padding-3xs);
  position: relative;
  gap: var(--gap-xl);
  font-size: var(--font-size-xs);
  color: var(--color-gray-100);
}
.example-1,
.l10 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 67px;
  z-index: 1;
}
.l10 {
  min-width: 48px;
}
.l-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs) 0 0;
}
.l11 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}
.example-1-parent,
.group-3-copy-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.example-1-parent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.group-3-copy-inner {
  width: 264px;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
}
.rectangle-copy-44 {
  height: 1px;
  width: 283px;
  position: relative;
  z-index: 1;
}
.rectangle-copy-4-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs);
}
.example-2,
.l12 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 67px;
  z-index: 1;
}
.l12 {
  min-width: 48px;
}
.l-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs) 0 0;
}
.l13 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}
.example-2-parent,
.group-3-copy-child {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.example-2-parent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.group-3-copy-child {
  width: 264px;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
}
.rectangle-copy-45 {
  height: 1px;
  width: 283px;
  position: relative;
  z-index: 1;
}
.rectangle-copy-4-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs);
}
.example-3,
.l14 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 67px;
  z-index: 1;
}
.l14 {
  min-width: 48px;
}
.l-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs) 0 0;
}
.l15 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}
.example-3-parent,
.group-3-copy-inner1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.example-3-parent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.group-3-copy-inner1 {
  width: 264px;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
}
.rectangle-copy-46 {
  height: 1px;
  width: 283px;
  position: relative;
  z-index: 1;
}
.rectangle-copy-4-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs);
}
.example-4,
.l16 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 67px;
  z-index: 1;
}
.l16 {
  min-width: 48px;
}
.l-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs) 0 0;
}
.l17 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}
.example-4-parent,
.group-3-copy-inner2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.example-4-parent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.group-3-copy-inner2 {
  width: 264px;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
}
.rectangle-copy-47 {
  height: 1px;
  width: 283px;
  position: relative;
  z-index: 1;
}
.rectangle-copy-4-wrapper1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs);
}
.example-5,
.l18 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 67px;
  z-index: 1;
}
.l18 {
  min-width: 48px;
}
.l-wrapper2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs) 0 0;
}
.l19 {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}
.example-5-parent,
.group-3-copy-inner3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.example-5-parent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.group-3-copy-inner3 {
  width: 264px;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
}
.cost-table,
.group-3-copy {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}
.group-3-copy {
  align-self: stretch;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--br-7xs);
  background-color: var(--color-white);
  overflow: hidden;
  align-items: flex-start;
  padding: var(--padding-xl) var(--padding-xl) var(--padding-sm);
  box-sizing: border-box;
  gap: var(--gap-mini-6);
}
.cost-table {
  width: 333px;
  align-items: flex-end;
  gap: var(--gap-6xl-3);
  min-width: 333px;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray-200);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 1000px) {
  .cost-table {
    flex: 1;
  }
}
@media screen and (max-width: 975px) {
  .separator1 {
    font-size: var(--font-size-19xl);
    line-height: 14px;
  }
}
@media screen and (max-width: 450px) {
  .separator1 {
    font-size: var(--font-size-10xl);
    line-height: 11px;
  }
}
