@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-sm: 14px;
  --font-size-xs: 12px;
  --font-size-lg: 18px;
  --font-size-smi: 13px;
  --font-size-29xl: 48px;
  --font-size-10xl: 29px;
  --font-size-19xl: 38px;
  --font-size-4xs: 9px;
  --font-size-2xs: 11px;
  --font-size-13xl: 32px;
  --font-size-lgi: 19px;
  --font-size-7xl: 26px;
  --font-size-2xl: 21px;
  --font-size-mini: 15px;

  /* Colors */
  --color-white: #fff;
  --color-dimgray-100: #646a6d;
  --color-dimgray-200: #57636e;
  --color-dimgray-300: rgba(87, 99, 110, 0.5);
  --color-gray-100: #1c232c;
  --color-gray-200: rgba(255, 255, 255, 0.1);
  --color-lightgray-100: #d5d5d5;
  --color-lightgray-200: #cbd5e1;
  --color-lightgray-300: #cccfda;
  --color-darkslategray-100: #475159;
  --color-darkslategray-200: #343c44;
  --color-darkslategray-300: #333;
  --color-gainsboro: #e6e6e6;
  --color-cornflowerblue: #3275cb;
  --color-slategray: #7b858f;
  --color-silver: #babcbe;
  --color-mediumseagreen: #2cb367;
  --color-darkgray: #9da2a8;

  /* Gaps */
  --gap-11xl: 30px;
  --gap-mini: 15px;
  --gap-6xl: 25px;
  --gap-6xl-3: 25.3px;
  --gap-mini-6: 14.6px;
  --gap-xl: 20px;
  --gap-7xl-5: 26.5px;
  --gap-3xs: 10px;
  --gap-2xl: 21px;
  --gap-5xs: 8px;
  --gap-sm-8: 13.8px;
  --gap-12xl: 31px;
  --gap-2xs: 11px;
  --gap-xs: 12px;
  --gap-sm: 14px;
  --gap-5xs-3: 7.3px;
  --gap-12xs: 1px;
  --gap-8xs: 5px;
  --gap-7xl-9: 26.9px;
  --gap-mini-2: 14.2px;
  --gap-7xl-7: 26.7px;
  --gap-15xl: 34px;
  --gap-mini-8: 14.8px;
  --gap-base-8: 15.8px;
  --gap-20xl: 39px;
  --gap-15xl-6: 34.6px;
  --gap-mini-9: 14.9px;

  /* Paddings */
  --padding-11xl: 30px;
  --padding-5xl: 24px;
  --padding-xl: 20px;
  --padding-sm: 14px;
  --padding-3xs: 10px;
  --padding-8xs: 5px;
  --padding-xs: 12px;
  --padding-mini: 15px;
  --padding-8xs-3: 4.3px;
  --padding-7xs: 6px;
  --padding-2xs: 11px;
  --padding-6xs: 7px;
  --padding-4xl: 23px;
  --padding-6xl: 25px;
  --padding-10xs: 3px;
  --padding-3xl: 22px;
  --padding-xs-7: 11.7px;
  --padding-lg: 18px;
  --padding-12xs: 1px;
  --padding-7xl: 26px;
  --padding-5xs: 8px;
  --padding-11xs: 2px;
  --padding-4xs: 9px;
  --padding-5xs-8: 7.8px;
  --padding-smi: 13px;
  --padding-9xs: 4px;
  --padding-mid: 17px;
  --padding-7xs-1: 5.1px;
  --padding-lgi: 19px;
  --padding-2xl: 21px;
  --padding-base: 16px;
  --padding-13xl: 32px;
  --padding-9xs-5: 3.5px;
  --padding-32xl: 51px;
  --padding-11xs-6: 1.6px;
  --padding-23xl: 42px;
  --padding-25xl: 44px;
  --padding-207xl-3: 226.3px;
  --padding-128xl: 147px;
  --padding-14xl: 33px;
  --padding-xs-8: 11.8px;
  --padding-2xs-9: 10.9px;

  /* Border radiuses */
  --br-7xs: 6px;
  --br-8xs: 5px;
  --br-5xs: 8px;
  --br-10xs: 3px;
  --br-9xs: 4px;
}
