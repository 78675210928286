.rectangle3 {
  height: 40px;
  width: 134px;
  position: relative;
  border-radius: var(--br-5xs);
  background: linear-gradient(45.9deg, #5d9bec, #4fc0e8);
  display: none;
}
.development-container-child {
  height: 17.3px;
  width: 17.3px;
  position: relative;
  min-height: 17px;
  z-index: 2;
}
.dashboard {
  position: relative;
  font-size: var(--font-size-smi);
  letter-spacing: 0.3px;
  font-weight: 500;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: left;
  display: inline-block;
  min-width: 66px;
  z-index: 2;
}
.development-container,
.development-container-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.development-container {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs-8) var(--padding-smi) var(--padding-2xs-9);
  background-color: transparent;
  flex: 1;
  border-radius: var(--br-5xs);
  background: linear-gradient(45.9deg, #5d9bec, #4fc0e8);
  gap: var(--gap-mini-9);
  z-index: 1;
}
.development-container-not-selected,
.development-container-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.development-container-not-selected {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs-8) var(--padding-smi) var(--padding-2xs-9);
  background-color: transparent;
  flex: 1;
  color: black;
  border-radius: var(--br-5xs);
  background: '#54575B';
  gap: var(--gap-mini-9);
  z-index: 1;
}
.development-container-wrapper {
  align-self: stretch;
  padding: 0 var(--padding-xl);
}
.report-filters-child,
.report-filters-item {
  width: 18.3px;
  height: 19px;
  position: relative;
  z-index: 1;
}
.report-filters-item {
  width: 18px;
  height: 18px;
}
.filter-dropdown-child,
.report-filters-inner {
  width: 17.5px;
  height: 18.2px;
  position: relative;
  z-index: 1;
}
.filter-dropdown-child {
  height: 18px;
  width: 15.4px;
}
.filter-dropdown {
  width: 19px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-12xs) 0 var(--padding-11xs);
  box-sizing: border-box;
}
.report-filters {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-15xl-6);
}
.products,
.research-data,
.team,
.transactions {
  position: relative;
  letter-spacing: 0.28px;
  font-weight: 500;
  display: inline-block;
  min-width: 51px;
  z-index: 1;
}
.research-data,
.team,
.transactions {
  min-width: 74px;
}
.research-data,
.team {
  min-width: 32px;
}
.research-data {
  min-width: 82px;
}
.report-title {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-20xl);
}
.report-filters-parent,
.report-title,
.report-title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.report-title-wrapper {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xs) 0 0;
}
.report-filters-parent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-sm);
}
.report-header-child {
  height: 18px;
  width: 16.2px;
  position: relative;
  min-height: 18px;
  z-index: 1;
}
.reports {
  position: relative;
  letter-spacing: 0.28px;
  font-weight: 500;
  display: inline-block;
  min-width: 45px;
  z-index: 1;
}
.report-header,
.reports-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.reports-wrapper {
  flex-direction: column;
  padding: var(--padding-10xs) 0 0;
}
.report-header {
  flex-direction: row;
  gap: var(--gap-base-8);
}
.report-header-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-12xs);
}
.frame-container,
.frame-div,
.report-header-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-div {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-15xl);
}
.frame-container {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-7xl) 0 var(--padding-14xl);
}
.path-32-icon {
  align-self: stretch;
  height: 1px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.settings-content-child {
  height: 20px;
  width: 18.2px;
  position: relative;
  min-height: 20px;
  z-index: 1;
}
.settings {
  position: relative;
  letter-spacing: 0.28px;
  font-weight: 500;
  display: inline-block;
  min-width: 47px;
  z-index: 1;
}
.settings-content,
.settings-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.settings-wrapper {
  flex-direction: column;
  padding: var(--padding-10xs) 0 0;
}
.settings-content {
  flex-direction: row;
  gap: var(--gap-mini-8);
}
.group-input,
.help {
  position: relative;
  z-index: 1;
}
.group-input {
  margin: 0;
  height: 18px;
  width: 18px;
  min-height: 18px;
}
.help {
  letter-spacing: 0.28px;
  font-weight: 500;
  display: inline-block;
  min-width: 26px;
}
.help-content,
.help-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.help-wrapper {
  flex-direction: column;
  padding: var(--padding-11xs) 0 0;
}
.help-content {
  flex-direction: row;
  gap: var(--gap-sm);
}
.help-content-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-12xs);
}
.frame-group,
.frame-wrapper1,
.help-content-wrapper,
.settings-content-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.settings-content-parent {
  flex-direction: column;
  gap: var(--gap-15xl);
}
.frame-group,
.frame-wrapper1 {
  flex-direction: row;
  padding: 0 var(--padding-14xl);
}
.frame-group {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 103px;
  gap: var(--gap-7xl-7);
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-darkslategray-100);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 725px) {
  .frame-group {
    padding-bottom: var(--padding-128xl);
    box-sizing: border-box;
  }
}
