.rectangle {
  align-self: stretch;
  height: 1000px;
  position: relative;
  background-color: var(--color-white);
  display: none;
}
.logo-icon {
  height: 30px;
  width: 122.7px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}
.card-row-two,
.logo-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.logo-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-xl);
}
.card-row-two {
  position: fixed;
  width: 200px;
  background-color: var(--color-white);
  flex-direction: column;
  padding: 29px 0 28.5px;
  box-sizing: border-box;
  gap: 51px;
}
.rectangle-icon {
  height: 35px;
  width: 1px;
  position: absolute;
  margin: 0 !important;
  top: -11px;
  left: 4px;
}
.business-dashboard1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
}
.rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.business-dashboard2 {
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  z-index: 2;
  margin: 0 0 0-240px;
}
.card-row-one {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangle-icon1 {
  width: 200px;
  height: 105px;
  position: relative;
  border-radius: var(--br-5xs);
  display: none;
}
.k {
  text-decoration: none;
  position: relative;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 72px;
  z-index: 1;
}
.vector-icon {
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 10px;
  transform: scale(9.2);
}
.wrapper-vector {
  position: absolute;
  top: 7.9px;
  left: 1.1px;
  width: 25.7px;
  height: 7.5px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.path-33-icon {
  width: 100%;
  height: 100%;
  z-index: 2;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 10px;
  transform: scale(8.688);
}
.wrapper-path-33 {
  position: absolute;
  top: 3.1px;
  left: 7px;
  width: 14.2px;
  height: 8px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vector-icon1 {
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 10px;
  transform: scale(41.467);
}
.wrapper-vector1 {
  position: absolute;
  top: 15.4px;
  left: 14px;
  width: 1.5px;
  height: 13.2px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vector-icon2,
.wrapper-vector2 {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.vector-icon2 {
  object-fit: contain;
  top: 10px;
  transform: scale(3.16);
}
.wrapper-vector2 {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trend-icons {
  position: absolute;
  top: 0;
  left: 0;
  width: 28.1px;
  height: 29.2px;
}
.vector-icon3,
.wrapper-vector3 {
  position: absolute;
  left: 0;
  z-index: 4;
}
.vector-icon3 {
  width: 100%;
  height: 100%;
  object-fit: contain;
  top: 10px;
  transform: scale(7.029);
}
.wrapper-vector3 {
  top: 0;
  width: 10.2px;
  height: 10.2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vector-icon4 {
  width: 100%;
  height: 100%;
  z-index: 5;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 10px;
  transform: scale(30.286);
}
.wrapper-vector4 {
  position: absolute;
  top: 9px;
  left: 9.1px;
  width: 2.1px;
  height: 2.1px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper-vector-parent {
  position: absolute;
  top: 17.6px;
  left: 20.3px;
  width: 11.2px;
  height: 11.1px;
}
.trend-container {
  width: 31.5px;
  height: 29.2px;
  position: relative;
}
.metric-title,
.metric-trend {
  display: flex;
  align-items: flex-start;
}
.metric-trend {
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0 0;
}
.metric-title {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.product,
.viewed {
  margin: 0;
}
.product-viewed {
  position: relative;
  letter-spacing: 0.18px;
  line-height: 14px;
  z-index: 1;
}
.path-3-icon,
.period-value {
  position: relative;
  flex-shrink: 0;
}
.period-value {
  letter-spacing: 0.17px;
  font-weight: 500;
  display: inline-block;
  min-width: 25px;
}
.path-3-icon {
  width: 0.9px;
  height: 5.6px;
}
.period-dropdown,
.period-icon,
.period-icon-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.period-dropdown {
  flex-direction: column;
  padding: 4.9px 0 0;
}
.period-icon,
.period-icon-wrapper {
  flex-direction: row;
}
.period-icon {
  gap: var(--gap-5xs-3);
}
.period-icon-wrapper {
  padding: 0 var(--padding-10xs) 0 var(--padding-13xl);
}
.from-last-month {
  position: relative;
  font-size: var(--font-size-4xs);
  letter-spacing: 0.3px;
  color: var(--color-silver);
  text-align: left;
  display: inline-block;
  min-width: 69px;
  white-space: nowrap;
}
.path-4-icon {
  width: 5.6px;
  height: 2.8px;
  position: absolute;
  margin: 0 !important;
  top: 3.5px;
  right: 0.4px;
  z-index: 1;
}
.frame-parent,
.metric-period {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent {
  overflow: hidden;
  position: relative;
  gap: var(--gap-12xs);
  z-index: 1;
}
.metric-period {
  padding: var(--padding-10xs) 0 0;
  text-align: right;
  font-size: var(--font-size-2xs);
  color: var(--color-mediumseagreen);
}
.product-viewed-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  font-size: var(--font-size-xs);
  color: var(--color-dimgray-200);
}
.metrics-container,
.rectangle-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangle-group {
  align-self: stretch;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  overflow: hidden;
  padding: var(--padding-smi) var(--padding-lg) var(--padding-base)
    var(--padding-xl);
  gap: var(--gap-3xs);
}
.metrics-container {
  flex: 0.9804;
  padding: 0 var(--padding-7xs) 0 0;
  box-sizing: border-box;
  min-width: 184px;
  max-width: 206px;
}
.rectangle-icon2 {
  height: 105px;
  width: 200px;
  position: relative;
  border-radius: var(--br-5xs);
  display: none;
}
.k1 {
  text-decoration: none;
  position: relative;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 72px;
}
.product1,
.shared {
  margin: 0;
}
.product-shared {
  position: relative;
  font-size: var(--font-size-xs);
  letter-spacing: 0.18px;
  line-height: 14px;
  color: var(--color-dimgray-200);
}
.k-parent {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  z-index: 1;
}
.wrapper-group-10-child {
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 10px;
  transform: scale(2.922);
}
.wrapper-group-10 {
  width: 32px;
  height: 32px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trend-value {
  position: relative;
  letter-spacing: 0.17px;
  font-weight: 500;
  display: inline-block;
  min-width: 25px;
}
.frame-child {
  height: 6.5px;
  width: 5.6px;
  position: relative;
}
.trend-details-inner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-12xs);
}
.from-last-month1 {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 69px;
}
.trend-details {
  margin-right: -12px;
  width: 69px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3.3px;
  margin-top: -9.3px;
  text-align: left;
  font-size: var(--font-size-4xs);
  color: var(--color-silver);
}
.group-55-copy {
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-2xs) 0 0;
  z-index: 1;
}
.group-55-copy,
.metric-trend1,
.rectangle-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.metric-trend1 {
  height: 70px;
  flex: 1;
  flex-direction: column;
  gap: 13.5px;
  text-align: right;
  font-size: var(--font-size-2xs);
  color: #fa3952;
}
.rectangle-container {
  flex: 0.8182;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-lg) var(--padding-base)
    var(--padding-xl);
  box-sizing: border-box;
  gap: 22.8px;
  min-width: 151px;
  max-width: 200px;
}
.rectangle-icon3 {
  height: 105px;
  width: 200px;
  position: relative;
  border-radius: var(--br-5xs);
  display: none;
}
.k2 {
  text-decoration: none;
  position: relative;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 54px;
  z-index: 1;
}
.added-to-cart,
.product2 {
  margin: 0;
}
.product-added-to-container {
  position: relative;
  font-size: var(--font-size-xs);
  letter-spacing: 0.18px;
  line-height: 14px;
  color: var(--color-dimgray-200);
  z-index: 1;
}
.card-count,
.counter-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.counter-label {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.card-count {
  flex: 1;
  justify-content: flex-end;
  padding: 0 0 var(--padding-12xs);
}
.wrapper-group-13-child {
  height: 100%;
  width: 100%;
  z-index: 2;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 10px;
  transform: scale(3.099);
}
.wrapper-group-13 {
  height: 30.3px;
  width: 29.3px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.change-icon {
  width: 34px;
  height: 30.3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
}
.percent-symbol {
  position: relative;
  letter-spacing: 0.17px;
  font-weight: 500;
  display: inline-block;
  min-width: 25px;
}
.frame-item {
  width: 5.6px;
  height: 7px;
  position: relative;
}
.percentage-label,
.percentage-value,
.percentage-value-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.percentage-value-inner {
  flex-direction: column;
  padding: var(--padding-9xs-5) 0 0;
}
.percentage-label,
.percentage-value {
  flex-direction: row;
}
.percentage-value {
  gap: var(--gap-8xs);
}
.percentage-label {
  padding: 0 0 0 var(--padding-13xl);
}
.from-last-month2 {
  position: relative;
  font-size: var(--font-size-4xs);
  letter-spacing: 0.3px;
  color: var(--color-silver);
  text-align: left;
  display: inline-block;
  min-width: 69px;
  white-space: nowrap;
}
.group-55-copy-2 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  z-index: 2;
}
.month-change {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: 16.7px;
  text-align: right;
  font-size: var(--font-size-2xs);
  color: var(--color-mediumseagreen);
}
.frame-wrapper,
.group-div,
.month-change {
  display: flex;
  justify-content: flex-start;
}
.group-div {
  align-self: stretch;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  flex-direction: row;
  align-items: flex-end;
  padding: var(--padding-smi) var(--padding-mid) var(--padding-mini)
    var(--padding-xl);
  gap: 20.6px;
}
.frame-wrapper {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 var(--padding-11xs) 0 0;
  box-sizing: border-box;
  min-width: 151px;
  max-width: 202px;
}
.rectangle-copy-icon {
  height: 105px;
  width: 200px;
  position: relative;
  border-radius: var(--br-5xs);
  display: none;
}
.k3 {
  text-decoration: none;
  position: relative;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 72px;
}
.checked-out,
.product3 {
  margin: 0;
}
.product-checked-out-container {
  position: relative;
  font-size: var(--font-size-xs);
  letter-spacing: 0.18px;
  line-height: 14px;
  color: var(--color-dimgray-200);
}
.checkout-count,
.k-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.k-group {
  align-self: stretch;
  overflow: hidden;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  z-index: 1;
}
.checkout-count {
  flex: 1;
  justify-content: flex-end;
  padding: 0 0 var(--padding-12xs);
}
.wrapper-group-9-child {
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 10px;
  transform: scale(3.245);
}
.wrapper-group-9 {
  width: 32px;
  height: 27.4px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.change-percentage {
  position: relative;
  letter-spacing: 0.17px;
  font-weight: 500;
  display: inline-block;
  min-width: 25px;
}
.change-direction-child {
  width: 5.6px;
  height: 7px;
  position: relative;
}
.change-direction,
.difference-value,
.value-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.change-direction {
  flex-direction: column;
  padding: var(--padding-9xs-5) 0 0;
}
.difference-value,
.value-wrapper {
  flex-direction: row;
}
.value-wrapper {
  gap: var(--gap-8xs);
}
.difference-value {
  padding: 0 0 0 var(--padding-13xl);
}
.from-last-month3 {
  position: relative;
  font-size: var(--font-size-4xs);
  letter-spacing: 0.3px;
  color: var(--color-silver);
  text-align: left;
  display: inline-block;
  min-width: 69px;
  white-space: nowrap;
}
.group-55-copy-4 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  z-index: 1;
}
.monthly-change,
.rectangle-copy-parent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.monthly-change {
  height: 69px;
  flex: 1;
  flex-direction: column;
  gap: 16.6px;
  text-align: right;
  font-size: var(--font-size-2xs);
  color: var(--color-mediumseagreen);
}
.rectangle-copy-parent {
  flex: 0.8131;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-lgi) var(--padding-mini)
    var(--padding-xl);
  box-sizing: border-box;
  gap: 21.4px;
  min-width: 151px;
  max-width: 200px;
}
.metrics-container-parent,
.title-content {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.metrics-container-parent {
  margin: 0;
  flex-direction: row;
  justify-content: center;
  gap: 13.2px;
  white-space: nowrap;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-darkslategray-300);
  font-family: var(--font-roboto);
}
.title-content {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-12xl);
}
.rectangle-icon4 {
  width: 848px;
  height: 400px;
  position: relative;
  border-radius: var(--br-5xs);
  display: none;
  max-width: 100%;
}
.platform-view {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.36px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  min-width: 117px;
  z-index: 1;
}
.rectangle-copy-icon1 {
  height: 30px;
  width: 122px;
  position: relative;
  border-radius: var(--br-7xs);
  display: none;
}
.beyobo,
.path-icon {
  position: relative;
  z-index: 1;
}
.beyobo {
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 41px;
}
.path-icon {
  width: 9px;
  height: 5.1px;
  object-fit: contain;
}
.path-wrapper,
.rectangle-copy-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-7xs-1) 0 0;
}
.rectangle-copy-group {
  flex: 1;
  border-radius: var(--br-7xs);
  background-color: var(--color-white);
  border: 0 solid var(--color-lightgray-300);
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-5xs) var(--padding-smi) var(--padding-5xs)
    var(--padding-mini);
  gap: var(--gap-xl);
  z-index: 1;
}
.rectangle-copy-icon2 {
  height: 30px;
  width: 80px;
  position: relative;
  border-radius: var(--br-7xs);
  display: none;
}
.competitor-value {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 28px;
  z-index: 1;
}
.frame-inner {
  height: 14px;
  width: 14.5px;
  position: relative;
  min-height: 14px;
  z-index: 1;
}
.rectangle-copy-container {
  border-radius: var(--br-7xs);
  background-color: var(--color-white);
  border: 0 solid var(--color-lightgray-300);
  overflow: hidden;
  justify-content: flex-start;
  padding: var(--padding-5xs-8) var(--padding-3xs) var(--padding-5xs)
    var(--padding-smi);
  gap: var(--gap-sm-8);
  z-index: 1;
}
.platform-data,
.pricing-plans,
.rectangle-copy-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.platform-data {
  width: 214px;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--font-size-xs);
  color: var(--color-dimgray-100);
}
.pricing-plans {
  align-self: stretch;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.k4,
.k5,
.k6,
.k7,
.k8 {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 21px;
  z-index: 1;
}
.chart-container,
.chart-grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.chart-grid {
  gap: 27.5px;
}
.chart-container {
  padding: var(--padding-6xl) 0 0;
}
.card-row-one-child {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.data-bars {
  width: 18px;
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  background-color: var(--color-lightgray-200);
  z-index: 2;
}
.data-bars-wrapper {
  height: 89px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lgi) 0 0;
  box-sizing: border-box;
}
.rectangle-copy-6 {
  width: 18px;
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  background-color: var(--color-lightgray-200);
  z-index: 2;
}
.rectangle-copy-6-wrapper {
  height: 112px;
  width: 36.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lg) 0 0;
  box-sizing: border-box;
}
.rectangle-copy-8 {
  width: 18px;
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  background-color: var(--color-lightgray-200);
  z-index: 2;
}
.rectangle-copy-8-wrapper {
  height: 141px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lgi) 0 0;
  box-sizing: border-box;
}
.rectangle-copy-9 {
  width: 18px;
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  background-color: var(--color-lightgray-200);
  z-index: 2;
}
.rectangle-copy-9-wrapper {
  height: 74px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lgi) 0 0;
  box-sizing: border-box;
}
.rectangle-copy-10 {
  width: 18px;
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  background-color: var(--color-lightgray-200);
  z-index: 2;
}
.rectangle-copy-10-wrapper {
  height: 152px;
  width: 36.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lg) 0 0;
  box-sizing: border-box;
}
.rectangle-copy-17 {
  width: 18px;
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  background-color: var(--color-lightgray-200);
  z-index: 2;
}
.rectangle-copy-17-wrapper {
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lgi) 0 0;
  box-sizing: border-box;
}
.rectangle-copy-11 {
  height: 193px;
  width: 18px;
  position: relative;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  background: linear-gradient(22.11deg, #5d9bec, #4fc0e8);
  z-index: 2;
}
.jul-2024 {
  font-weight: 500;
  display: inline-block;
  min-width: 51px;
}
.beyobo1,
.jul-2024,
.path-2-icon {
  position: relative;
  z-index: 4;
}
.beyobo1 {
  display: inline-block;
  min-width: 40px;
  flex-shrink: 0;
}
.path-2-icon {
  height: 12.6px;
  width: 1px;
  mix-blend-mode: normal;
}
.bg-icon,
.oval {
  position: absolute;
}
.bg-icon {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 3;
}
.oval {
  top: 34.8px;
  left: 20.7px;
  border-radius: 50%;
  background: linear-gradient(29.48deg, #4fc0e8, #5d9bec);
  width: 7.7px;
  height: 7.7px;
  z-index: 4;
}
.order-value {
  height: 58.1px;
  width: 181px;
  position: absolute;
  margin: 0 !important;
  top: -31.2px;
  left: -96.1px;
}
.k-orders {
  display: inline-block;
  min-width: 67px;
  z-index: 4;
}
.k-orders,
.order-count {
  position: relative;
}
.order-count,
.path-2-parent,
.step-headers {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.path-2-parent {
  gap: var(--gap-5xs-3);
  flex-shrink: 0;
}
.step-headers {
  gap: 11.1px;
}
.rectangle-copy-12 {
  height: 112px;
}
.rectangle-copy-12,
.rectangle-copy-13,
.rectangle-copy-14 {
  width: 18px;
  position: relative;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  background-color: var(--color-lightgray-200);
  z-index: 2;
}
.rectangle-copy-13 {
  align-self: stretch;
}
.rectangle-copy-14 {
  height: 90px;
}
.step-details {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.competitor-bars,
.platform-tooltip {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.competitor-bars {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: 0 0 0 var(--padding-11xs);
}
.platform-tooltip {
  height: 164px;
  width: 147.7px;
  flex-direction: column;
  gap: 9px;
}
.rectangle-copy-15,
.rectangle-copy-16 {
  height: 152px;
  width: 18px;
  position: relative;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  background-color: var(--color-lightgray-200);
  z-index: 2;
}
.rectangle-copy-16 {
  height: 122px;
}
.bar-elements {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.bar-content,
.month-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.bar-content {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-base);
  font-size: var(--font-size-xs);
  color: var(--color-dimgray-200);
}
.month-label {
  width: 290.4px;
  flex-direction: column;
  gap: 6.3px;
}
.card-row-one1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 47px var(--padding-3xl) 0 var(--padding-2xl);
  position: relative;
  gap: var(--gap-xl);
}
.jan {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 21px;
  z-index: 1;
}
.months {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lgi) 0 0;
}
.feb {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 21px;
  z-index: 1;
}
.months1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
}
.mar {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 22px;
  z-index: 1;
}
.months2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lgi) 0 0;
}
.apr {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 20px;
  z-index: 1;
}
.months3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lgi) 0 0;
}
.may {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 24px;
  z-index: 1;
}
.months4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lgi) 0 0;
}
.jun {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 21px;
  z-index: 1;
}
.months5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lgi) 0 0;
}
.jul {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 18px;
  z-index: 1;
}
.months6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lg) 0 0;
}
.aug {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 23px;
  z-index: 1;
}
.months7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lgi) 0 0;
}
.sep {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 22px;
  z-index: 1;
}
.months8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
}
.oct {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 20px;
  z-index: 1;
}
.months9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
}
.nov,
.rectangle1 {
  position: relative;
  z-index: 1;
}
.nov {
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 23px;
}
.rectangle1 {
  width: 10px;
  height: 10px;
  border-radius: var(--br-10xs);
  background: linear-gradient(126.9deg, #4fc0e8, #5d9bec);
}
.november-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-11xs);
}
.dec {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 22px;
  z-index: 1;
}
.december-value {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs);
}
.legend-1 {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 42px;
  z-index: 1;
}
.video-title-container,
.video-titles,
.workflow-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xl);
}
.video-titles,
.workflow-details {
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-5xs);
}
.workflow-details {
  width: 738px;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
  font-size: var(--font-size-xs);
  color: var(--color-dimgray-200);
}
.chart-bars,
.development-content,
.platform-chart {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.chart-bars {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-sm);
  min-width: 493px;
  font-size: var(--font-size-smi);
  color: var(--color-darkslategray-200);
}
.development-content,
.platform-chart {
  align-self: stretch;
}
.platform-chart {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-2xs);
  font-size: var(--font-size-xs);
  color: var(--color-dimgray-200);
}
.development-content {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-xl) var(--padding-7xl) var(--padding-4xl)
    var(--padding-6xl);
  box-sizing: border-box;
  gap: 24px;
  font-size: var(--font-size-lg);
  color: var(--color-darkslategray-200);
}
.m,
.m1 {
  position: absolute;
  margin: 0 !important;
  font-weight: 500;
  display: inline-block;
  z-index: 4;
}
.m {
  top: 156px;
  right: -10.5px;
  min-width: 45px;
}
.m1 {
  right: -3.5px;
  bottom: 135px;
  min-width: 38px;
}
.rectangle-copy-2 {
  width: 490px;
  height: 330px;
  position: relative;
  border-radius: var(--br-5xs);
  display: none;
  max-width: 100%;
}
.cost-of-marketing {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.36px;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
}
.rectangle-copy-icon3 {
  height: 30px;
  width: 122px;
  position: relative;
  border-radius: var(--br-7xs);
  display: none;
}
.all-platforms {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 72px;
  z-index: 1;
}
.path-icon1 {
  width: 9px;
  height: 5.1px;
  position: relative;
  object-fit: contain;
  z-index: 1;
}
.path-container,
.rectangle-copy-parent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-7xs-1) 0 0;
}
.rectangle-copy-parent1 {
  border-radius: var(--br-7xs);
  background-color: var(--color-white);
  border: 0 solid var(--color-lightgray-300);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-smi) var(--padding-5xs)
    var(--padding-mini);
  gap: 12.9px;
  z-index: 1;
}
.rectangle-copy-icon4 {
  height: 30px;
  width: 80px;
  position: relative;
  border-radius: var(--br-7xs);
  display: none;
}
.competitor-dropdown {
  position: relative;
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 28px;
  z-index: 1;
}
.group-icon {
  height: 14px;
  width: 14.5px;
  position: relative;
  min-height: 14px;
  z-index: 1;
}
.rectangle-copy-parent2 {
  border-radius: var(--br-7xs);
  background-color: var(--color-white);
  border: 0 solid var(--color-lightgray-300);
  overflow: hidden;
  justify-content: flex-start;
  padding: var(--padding-5xs-8) var(--padding-3xs) var(--padding-5xs)
    var(--padding-smi);
  gap: var(--gap-sm-8);
  z-index: 1;
}
.cost-breakdown,
.rectangle-copy-parent2,
.service-description-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.service-description-content {
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--font-size-xs);
  color: var(--color-dimgray-100);
}
.cost-breakdown {
  align-self: stretch;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.group-23-copy-3 {
  height: 200px;
  width: 229.2px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}
.metric-labels-child {
  height: 7.7px;
  width: 7.7px;
  position: relative;
  z-index: 4;
}
.metric-labels {
  width: 10.3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
}
.total-profit,
.total-sales,
.total-spend {
  position: relative;
  display: inline-block;
  min-width: 59px;
  z-index: 4;
}
.total-profit,
.total-spend {
  min-width: 64px;
}
.total-profit {
  min-width: 59px;
}
.metric-names {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.metric-container {
  flex-direction: column;
  gap: 15.7px;
}
.metric-container,
.summary-content,
.summary-data {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.summary-data {
  flex-direction: column;
  padding: 41.6px 0 0;
}
.summary-content {
  flex-direction: row;
  gap: var(--gap-sm-8);
}
.check-our-pricing-pl {
  width: 10px;
  height: 10px;
  position: relative;
  border-radius: var(--br-10xs);
  background: linear-gradient(-46.49deg, #22b591, #2cb78b, #8fce4a);
  flex-shrink: 0;
  z-index: 1;
}
.legend-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.legend-11 {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 46px;
  flex-shrink: 0;
  z-index: 2;
}
.legend-items {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.7px;
}
.rectangle2 {
  width: 10px;
  height: 10px;
  position: relative;
  border-radius: var(--br-10xs);
  background: linear-gradient(-44.07deg, #fd8169, #f9b60b);
  flex-shrink: 0;
  z-index: 1;
}
.rectangle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.legend-12 {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 39px;
  flex-shrink: 0;
  z-index: 2;
}
.legend-items1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5.9px;
}
.maria-trofimova {
  width: 10px;
  height: 10px;
  position: relative;
  border-radius: var(--br-10xs);
  background: linear-gradient(140.57deg, #ec88c0, #ed5565);
  z-index: 1;
}
.maria-trofimova-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.legend-13 {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 43px;
  z-index: 2;
}
.legend-items2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.rectangle-copy-21 {
  width: 10px;
  height: 10px;
  position: relative;
  border-radius: var(--br-10xs);
  background: linear-gradient(126.9deg, #4fc0e8, #5d9bec);
  z-index: 1;
}
.rectangle-copy-21-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.legend-14 {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 42px;
  z-index: 1;
}
.legend-items3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.chart-container1,
.legend-items-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.legend-items-parent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.chart-container1 {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-mid);
  box-sizing: border-box;
  margin-top: -1px;
  color: var(--color-dimgray-200);
}
.financial-summary,
.portfolio-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}
.financial-summary {
  width: 349px;
  align-items: flex-start;
  font-size: var(--font-size-xs);
  color: var(--color-slategray);
}
.financial-summary, svg {
  position: relative;
}
.portfolio-content {
  flex: 1;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  align-items: flex-end;
  padding: var(--padding-11xl) var(--padding-6xl) var(--padding-7xl);
  box-sizing: border-box;
  gap: var(--gap-12xl);
  font-size: var(--font-size-lg);
  color: var(--color-darkslategray-200);
}
.bg-icon1,
.m2 {
  position: absolute;
}
.m2 {
  margin: 0 !important;
  right: -10.5px;
  bottom: 110px;
  font-weight: 500;
  display: inline-block;
  min-width: 45px;
  z-index: 4;
}
.bg-icon1 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 3;
}
.path-2-copy,
.path-2-copy1,
.path-2-copy2 {
  position: absolute;
  top: 38.2px;
  left: 104.3px;
  width: 1px;
  height: 12.6px;
  mix-blend-mode: normal;
  z-index: 4;
}
.path-2-copy1,
.path-2-copy2 {
  top: 63.2px;
}
.path-2-copy2 {
  top: 88.2px;
}
.platform-background {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.beyobo2 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 44px;
  z-index: 4;
}
.platform-background-parent,
.spend-values {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.platform-background-parent {
  height: 119.1px;
  width: 177px;
  margin: 0 !important;
  position: absolute;
  right: -25.9px;
  bottom: 93.1px;
  flex-direction: column;
  padding: 9.9px var(--padding-xl) var(--padding-3xs) 35px;
  box-sizing: border-box;
  font-size: var(--font-size-smi);
  color: var(--color-darkslategray-200);
}
.spend-values {
  flex: 1;
  flex-direction: row;
  position: relative;
  min-width: 318px;
  max-width: 100%;
}
.rectangle-copy-icon5 {
  width: 333px;
  height: 330px;
  position: relative;
  border-radius: var(--br-7xs);
  display: none;
  max-width: 100%;
}
.orders-vs-revenue {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.36px;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
}
.chart-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
}
.rectangle-copy-icon6 {
  height: 30px;
  width: 80px;
  position: relative;
  border-radius: var(--br-7xs);
  display: none;
}
.frame-child1,
.separator {
  position: relative;
  z-index: 1;
}
.separator {
  letter-spacing: 0.2px;
  display: inline-block;
  min-width: 28px;
}
.frame-child1 {
  height: 14px;
  width: 14.5px;
  min-height: 14px;
}
.chart-title-background,
.rectangle-copy-parent4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangle-copy-parent4 {
  border-radius: var(--br-7xs);
  background-color: var(--color-white);
  border: 0 solid var(--color-lightgray-300);
  overflow: hidden;
  padding: var(--padding-5xs-8) var(--padding-3xs) var(--padding-5xs)
    var(--padding-smi);
  gap: var(--gap-sm-8);
  z-index: 1;
  font-size: var(--font-size-xs);
  color: var(--color-dimgray-100);
}
.chart-title-background {
  gap: 45px;
}
.chart-bars-child,
.wrapper-group-85-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.wrapper-group-85-child {
  z-index: 2;
  object-fit: contain;
  top: -25px;
  transform: scale(1.58);
}
.wrapper-group-85 {
  position: absolute;
  top: 38px;
  left: 0;
  width: 283px;
  height: 121px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chart-bars1 {
  align-self: stretch;
  position: relative;
}
.chart-title-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 23px;
}
.feb1 {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 21px;
  z-index: 1;
}
.x-axis-labels {
  width: 31.8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs) 0 0;
  box-sizing: border-box;
}
.apr1 {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 20px;
  z-index: 1;
}
.x-axis-labels1 {
  width: 29.7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-4xs) 0 0;
  box-sizing: border-box;
}
.aug1,
.dec1,
.jun1,
.oct1 {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 21px;
  z-index: 1;
}
.aug1,
.dec1,
.oct1 {
  min-width: 23px;
}
.dec1,
.oct1 {
  min-width: 20px;
}
.dec1 {
  min-width: 22px;
}
.legend-labels {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25.9px;
}
.legend-15 {
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 38px;
  z-index: 1;
}
.marker-shape {
  flex: 0.8261;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
}
.rectangle-copy-211 {
  width: 10px;
  height: 10px;
  position: relative;
  border-radius: var(--br-10xs);
  background: linear-gradient(29.48deg, #4fc0e8, #5d9bec);
  z-index: 1;
}
.legend-marker-background {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.legend-16 {
  flex: 1;
  position: relative;
  letter-spacing: 0.3px;
  display: inline-block;
  min-width: 48px;
  z-index: 1;
}
.marker-container {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-5xs);
}
.chart-legend-background,
.legend-marker,
.marker-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.legend-marker {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-5xs);
}
.chart-legend-background {
  position: absolute;
  top: 0;
  left: 9.8px;
  width: 128.2px;
  flex-direction: column;
  gap: var(--gap-2xl);
}
.rectangle-copy-212 {
  position: absolute;
  top: 37px;
  left: 0;
  border-radius: var(--br-10xs);
  background: linear-gradient(140.57deg, #ec88c0, #ed5565);
  width: 10px;
  height: 10px;
  z-index: 1;
}
.chart-legend-container {
  height: 49px;
  flex: 1;
  position: relative;
}
.chart-x-axis,
.rectangle-copy-parent3 {
  display: flex;
  justify-content: flex-start;
}
.chart-x-axis {
  width: 267.3px;
  flex-direction: row;
  align-items: flex-start;
  gap: 15.6px;
  font-size: var(--font-size-xs);
  color: var(--color-dimgray-200);
}
.rectangle-copy-parent3 {
  width: 333px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--br-7xs);
  background-color: var(--color-white);
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-4xl) var(--padding-6xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  min-width: 333px;
  max-width: 100%;
  font-size: var(--font-size-lg);
  color: var(--color-darkslategray-200);
}
.studio-button {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  font-size: var(--font-size-xs);
  color: var(--color-dimgray-200);
}
.header-content,
.studio-button,
.title-content-parent {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-6xl);
  max-width: 85%;
}
.title-content-parent {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.header-content {
  align-self: stretch;
  flex-direction: row;
  text-align: left;
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray-300);
  font-family: var(--font-roboto);
}
.business-dashboard,
.page-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.page-header {
  margin-left: 220px;
  flex: 1;
  flex-direction: column;
  padding: var(--padding-5xl) 0 0;
  max-width: calc(100% - 204px);
}
.business-dashboard {
  width: 100%;
  position: relative;
  background-color: #eff3f4;
  overflow: hidden;
  flex-direction: row;
  padding: 0 var(--padding-11xl) 0 0;
  gap: var(--gap-11xl);
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1000px) {
  .header-content {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 975px) {
  .card-row-two {
    display: none;
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .k,
  .k1,
  .k2,
  .k3 {
    font-size: var(--font-size-7xl);
  }
  .rectangle-copy-11 {
    width: 100%;
    height: 18px;
  }
  .card-row-one1,
  .workflow-details {
    flex-wrap: wrap;
  }
  .page-header {
    max-width: 100%;
  }
  .business-dashboard {
    gap: var(--gap-mini);
    padding-left: var(--padding-11xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 725px) {
  .metrics-container-parent {
    flex-wrap: wrap;
  }
  .chart-bars {
    min-width: 100%;
  }
  .cost-breakdown,
  .platform-chart {
    flex-wrap: wrap;
  }
  .portfolio-content {
    gap: var(--gap-mini);
  }
  .rectangle-copy-parent3 {
    flex: 1;
  }
  .studio-button {
    flex-wrap: wrap;
  }
  .title-content-parent {
    min-width: 85%;
  }
}
@media screen and (max-width:972px) {
  .page-header{
    margin-left: 0;
  }
}
@media screen and (max-width: 450px) {
  .business-dashboard1,
  .business-dashboard2 {
    font-size: var(--font-size-2xl);
  }
  .k {
    font-size: var(--font-size-lgi);
  }
  .metrics-container {
    flex: 1;
  }
  .k1 {
    font-size: var(--font-size-lgi);
  }
  .rectangle-container {
    flex: 1;
  }
  .k2,
  .k3 {
    font-size: var(--font-size-lgi);
  }
  .rectangle-copy-parent {
    flex: 1;
  }
  .title-content {
    gap: var(--gap-mini);
  }
  .pricing-plans {
    flex-wrap: wrap;
  }
  .rectangle-copy-15,
  .rectangle-copy-16 {
    width: 100%;
    height: 18px;
  }
  .bar-elements {
    flex-wrap: wrap;
  }
  .development-content {
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .legend-items-parent,
  .summary-content {
    flex-wrap: wrap;
  }
  .chart-title-background {
    gap: 22px;
  }
}
